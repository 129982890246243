import React, { ReactNode, Component } from 'react';
import { ThemeProvider } from 'styled-components';
import themes from '@bitcoin-portal/bitcoincom-pkg-theme';

import StickyContainer from './components/StickyContainer';

import { LOCALES } from './settings';
import Content from './components/Content';

interface Props {
  contrast?: boolean;
  links?: LinksStrings[];
  buttons?: ButtonsStrings[];
  logo?: ReactNode;
  isReact?: boolean;
  locale?: string;
  callback?: ((locale: string) => void) | string;
  navbarAlign?: string;
  logoUrl?: string;
}

interface State {
  contrast: boolean;
  loaded: boolean;
  locale?: string;
  links: LinksStrings[];
  buttons: ButtonsStrings[];
  social: SocialStrings[];
  logoUrl: string;
  country: string;
  navbarAlign: string;
}

class Navbar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      contrast = false,
      locale = 'en',
      logoUrl = 'https://www.bitcoin.com',
    } = props;

    this.state = {
      contrast,
      loaded: false,
      locale,
      links: [],
      buttons: [],
      social: [],
      logoUrl,
      country: '',
      navbarAlign: 'right',
    };
  }

  componentDidMount(): void {
    const { locale } = this.props;
    this.setState({ loaded: true, locale });
    this.getStrings();

    this.setContrast = this.setContrast.bind(this);
    this.setLocale = this.setLocale.bind(this);
  }

  static getDerivedStateFromProps(nextProps: Props, nextState: State): State {
    const { isReact = true, locale } = nextProps;
    const { loaded } = nextState;

    let { contrast } = nextState;
    if (isReact && typeof nextProps.contrast !== 'undefined')
      contrast = nextProps.contrast;

    return {
      ...nextState,
      contrast,
      loaded,
      locale: isReact ? locale : nextState.locale,
    };
  }

  async getStrings(): Promise<void> {
    const { locale = 'en' } = this.state;
    const { links, buttons, navbarAlign, logoUrl } = this.props;

    const res = LOCALES.includes(locale)
      ? await import(`./i18n/${locale}.json`)
      : await import('./i18n/en.json');

    this.setState({
      links: links || res.links,
      buttons: buttons || res['account-buttons'],
      logoUrl: logoUrl || res.logo,
      navbarAlign: navbarAlign || 'right',
    });
  }

  setContrast = (contrast: boolean): void => {
    this.setState({ contrast });
  };

  setLocale = (locale: string): void => {
    const { callback } = this.props;
    if (typeof callback === 'string') {
      window[callback].call(this, locale);
    }

    if (typeof callback === 'function') {
      callback(locale);
    }

    this.setState({ locale }, () => {
      this.getStrings();
    });
  };

  render() {
    const { contrast, loaded, links, buttons, logoUrl, navbarAlign } =
      this.state;
    const { logo } = this.props;
    const theme = themes.light;

    if (loaded)
      return (
        <ThemeProvider theme={theme}>
          <StickyContainer contrast={contrast}>
            <Content
              logo={logo}
              links={links}
              buttons={buttons}
              logoUrl={logoUrl}
              contrast={contrast}
              navbarAlign={navbarAlign}
            />
          </StickyContainer>
        </ThemeProvider>
      );

    return <></>;
  }
}

export default Navbar;
